import { render, staticRenderFns } from "./CardLoadingSkeleton.vue?vue&type=template&id=78b3e855&scoped=true"
var script = {}
import style0 from "./CardLoadingSkeleton.vue?vue&type=style&index=0&id=78b3e855&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "78b3e855",
  null
  
)

export default component.exports