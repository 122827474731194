<template>
  <div v-capture-scroll="handleScroll">
    <el-tooltip v-if="tooltipText !== undefined" placement="top-end" effect="dark" :content="tooltipText">
      <el-dropdown
        ref="dropdown"
        class="d-flex"
        trigger="click"
        @command="onDropdownClick"
        @visible-change="(visible) => (dropdownOpen = visible)"
      >
        <Button type="secondary" class="dropdown-button" :disabled="disabled">
          <slot></slot>
        </Button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item
            v-for="(dropdownOption, index) in dropdownOptions"
            :key="`buttonDropdown${index}`"
            :command="dropdownOption.action"
            >{{ dropdownOption.label }}</el-dropdown-item
          >
        </el-dropdown-menu>
      </el-dropdown>
    </el-tooltip>
    <el-dropdown
      v-else
      ref="dropdown"
      class="d-flex"
      trigger="click"
      @command="onDropdownClick"
      @visible-change="(visible) => (dropdownOpen = visible)"
    >
      <Button type="secondary" class="dropdown-button" :disabled="disabled">
        <div class="button-content">
          {{ text }}
          <slot></slot>
        </div>
      </Button>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item
          v-for="(dropdownOption, index) in dropdownOptions"
          :key="`buttonDropdown${index}`"
          :command="dropdownOption.action"
          >{{ dropdownOption.label }}</el-dropdown-item
        >
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>
<script>
import { ref } from 'vue';
import { Button } from './';

import { captureScroll } from '@/directives/capture-scroll';

export default {
  directives: {
    captureScroll,
  },
  components: {
    Button,
  },
  props: {
    disabled: { type: Boolean, default: false },
    dropdownOptions: { type: Array, required: true },
    tooltipText: { type: String, default: undefined },
    text: { type: String, default: undefined },
    shouldCloseDropdownOnScroll: { type: Boolean, default: false },
  },
  emits: ['on-dropdown-choice'],
  setup(props, { emit }) {
    const dropdown = ref(null);
    const dropdownOpen = ref(false);

    const onDropdownClick = (action) => {
      emit('on-dropdown-choice', { action });
    };

    const handleScroll = () => {
      if (props.shouldCloseDropdownOnScroll && dropdown.value && dropdownOpen.value) {
        dropdown.value.hide();
      }
    };

    return {
      dropdown,
      dropdownOpen,
      handleScroll,
      onDropdownClick,
    };
  },
};
</script>
<style lang="scss" scoped>
.dropdown-button {
  padding: 0.25rem;
  margin: 0;
}
.button-content {
  display: flex;
  gap: 0.25rem;
  align-items: center;
  padding-inline-start: 0.25rem;
}
</style>
