<template>
  <el-button
    class="border-0"
    :class="{ ['link-underline']: type === 'link', ['icon-btn']: type === 'icon' }"
    :disabled="disabled"
    :loading="loading"
    :circle="circle"
    :type="btnType"
    :size="size"
    @click="$emit('click', $event)"
  >
    <slot />
  </el-button>
</template>

<script>
export default {
  name: 'Button',
  props: {
    type: {
      type: String,
      default: 'primary',
      validator: (value) =>
        ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'text', 'icon', 'link'].includes(value),
    },
    size: {
      type: String,
      default: 'small',
      validator: (value) => ['medium', 'small', 'mini', 'large'].includes(value),
    },
    disabled: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    circle: { type: Boolean, default: false },
  },
  computed: {
    btnType() {
      if (this.type === 'icon' || this.type === 'link') return 'text';
      return this.type;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/stylesheets/scss/global';
.link-underline {
  font-weight: $font-weight-normal;
  &:hover {
    text-decoration: underline;
  }
}

.icon-btn {
  height: fit-content;
  padding: $button-icon-padding;
  color: $typography-primary;
  &.is-disabled {
    color: $typography-disabled;
  }
  &:not(.is-disabled):hover {
    background: $secondary;
  }
}
</style>
