import { render, staticRenderFns } from "./DashboardCard.vue?vue&type=template&id=f1d6fae6&scoped=true"
import script from "./DashboardCard.vue?vue&type=script&lang=js"
export * from "./DashboardCard.vue?vue&type=script&lang=js"
import style0 from "./DashboardCard.vue?vue&type=style&index=0&id=f1d6fae6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f1d6fae6",
  null
  
)

export default component.exports