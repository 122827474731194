<template>
  <el-dialog
    id="dialog-element"
    :show-close="false"
    :visible.sync="toggleDialog"
    append-to-body
    :before-close="() => $emit('on-close')"
    custom-class="action-modal"
  >
    <template v-if="title" #title>
      <div class="d-flex justify-content-between align-items-center">
        <div class="d-flex">
          <ErrorFullIcon v-if="dialogType === DIALOG_TYPES.DANGER" class="dialog-title-icon" />
          <WarningIcon v-if="dialogType === DIALOG_TYPES.WARNING" class="dialog-title-icon" color="#F38300" />

          <div class="dialog-title">{{ title }}</div>
        </div>

        <Button type="text" class="p-0 text-typography-primary close-btn" @click="$emit('on-cancel')">
          <CloseIcon />
        </Button>
      </div>
      <slot name="subtitle" />
    </template>

    <div class="dialog-content text-break">
      <template v-if="hasContentSlot">
        <slot name="content" />
      </template>

      <FormattedText v-else-if="content" :text="content" />
    </div>

    <template #footer>
      <span class="dialog-footer">
        <Button size="large" type="secondary" @click="$emit('on-cancel')">{{ cancelButtonText }}</Button>
        <Button
          :disabled="confirmButtonDisabled"
          :loading="confirmButtonLoading"
          size="large"
          :type="dialogType"
          @click="$emit('on-confirm')"
        >
          {{ confirmButtonText }}</Button
        >
      </span>
    </template>
  </el-dialog>
</template>
<script>
import { CloseIcon, ErrorFullIcon, WarningIcon } from '@/assets/icons';
import Button from './Button';
import { DIALOG_TYPES } from './constants';
import FormattedText from '@/modules/purchase-management/components/FormattedText.vue';

export default {
  name: 'ActionsModal',
  components: { CloseIcon, Button, ErrorFullIcon, WarningIcon, FormattedText },
  props: {
    title: { type: String, default: null },
    content: { type: String, default: null },
    toggleDialog: { type: Boolean, default: false },
    confirmButtonText: { type: String, default: null },
    cancelButtonText: { type: String, default: null },
    dialogType: {
      type: String,
      required: true,
      validator: (value) => Object.values(DIALOG_TYPES).includes(value),
    },
    confirmButtonLoading: { type: Boolean, default: false },
    confirmButtonDisabled: { type: Boolean, default: false },
  },
  emits: ['on-cancel', 'on-confirm'],
  setup(_, { slots }) {
    return {
      DIALOG_TYPES,
      hasContentSlot: !!slots.content,
    };
  },
};
</script>
<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

::v-deep {
  .el-dialog__header {
    padding: 1rem;
    > div {
      padding: 0 !important;
    }
  }

  .el-dialog__body {
    padding: 1rem !important;
    padding-top: 0 !important;
  }

  .el-dialog__footer {
    padding: 1rem;
  }
}

#dialog-element {
  margin: 0 auto;
  margin-top: 20vh;

  ::v-deep .el-dialog {
    border-radius: 0.5rem !important;
    width: 25rem;
  }

  .dialog-title {
    color: #131416;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.75rem;
    letter-spacing: -0.015rem;
  }

  .dialog-content {
    color: black;
  }

  .dialog-title-icon {
    width: 24px;
    height: 24px;
    margin-top: 2px;
    margin-inline-end: 0.5rem;

    > svg {
      width: 100%;
      height: 100%;
    }
  }

  .close-btn {
    height: fit-content;
    &:hover {
      background: $secondary;
    }
  }
}
</style>
